@import '../../../../styles/variables';

.editor {
  min-height: 148px;
  border-radius: 3px;
  background: #FBFBFB;
  border: 1px solid #DFDFDF;
  padding: 0px 10px;
  .public-DraftStyleDefault-block {
    margin: 0px;
  }
}